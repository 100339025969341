import { Route } from "react-router-dom";

import { useAuth } from "../contexts/AuthContext";

import Login from "../pages/authentication/Login";
import Unauthorized from "../pages/error/Unauthorized";

const ProtectedRoute = ({
  component: Component,
  allowedUserTypes,
  isRestricted,
  ...rest
}) => {
  const { currentUser, userType } = useAuth();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!currentUser) {
          // Not authenticated -> Render login layout
          return <Login />;
        }

        if (isRestricted) {
          // Component is restricted to a specific role
          if (allowedUserTypes.includes(userType)) {
            // Role is authorized -> Render the component
            return <Component {...props} />;
          } else {
            // Role is unauthorized -> Render unauthorized layout
            return <Unauthorized isUserLoggedIn={currentUser ? true : false} />;
          }
        }

        // Authenticated and not restricted -> Render the component
        return <Component {...props} />;
      }}
    />
  );
};

export default ProtectedRoute;
