import { useReducer } from "react";

const toastInitialState = {
  message: "",
  status: "",
};

const toastReducer = (state, action) => {
  if (action.payload) return action.payload;

  try {
    throw new Error("No payload was dispatched into toast reducer.");
  } catch (error) {
    console.error(error);
    return state;
  }
};

const useToast = () => {
  const [toastState, dispatchToast] = useReducer(
    toastReducer,
    toastInitialState
  );

  return { toastState, dispatchToast };
};

export default useToast;
