export const testEmailValidation = (email) => {
  const emailRegex =
    /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return emailRegex.test(email);
};

// Check if form fields are not empty
export const checkRequiredFormInputs = (obj) => {
  for (let key in obj) {
    if (
      obj[key]?.isRequired &&
      (obj[key]?.value === "" ||
        obj[key]?.value === null ||
        (Array.isArray(obj[key]?.value) && obj[key]?.value.length === 0))
    ) {
      return false;
    }

    // Check if property type is email and its value is a valid email
    if (
      obj[key]?.type === "email" &&
      obj[key]?.value !== "" &&
      !testEmailValidation(obj[key]?.value)
    ) {
      return false;
    }
  }

  return true;
};

// Debouncing
let timerId;
export const debounce = (func, delay) => {
  clearTimeout(timerId);

  timerId = setTimeout(func, delay);
};

// Transform a blob into a file
export const blobToFile = (blob, fileName) => {
  return new File([blob], fileName, {
    lastModified: new Date().getTime(),
    type: blob.type,
  });
};

// Check if a license is about to expire
export const isLicenseAboutToExpire = (date) => {
  let currentTime = new Date().getTime();
  let expiryTime = new Date(date).getTime();
  const remainingTime = 864000000; // 10 days

  return expiryTime - currentTime <= remainingTime;
};

// Get locale to render for data grid
export const getLocale = (params) => {
  return params?.row?.storyLanguage?.locale;
};

// Get story topic to render for data grid
export const getStoryTopic = (params) => {
  return params?.row?.storyTopic?.topic;
};

// Get number of stories per story topic to render for data grid
export const getStoriesInStoryTopic = (params) => {
  return params?.row?.storyTopic?.totalNumberOfStories;
};

// Get story duration to render for data grid
export const getStoryDuration = (params) => {
  return params?.row?.storyDuration?.duration;
};

// Get manager email to render for data grid
export const getManagerEmail = (params) => {
  return params?.row?.license?.managerEmail;
};

// Get client name to render for data grid
export const getClientName = (params) => {
  return params?.row?.client?.name;
};
