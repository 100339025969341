import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAuth } from "../../contexts/AuthContext";
import ToastContext from "../../contexts/ToastContext";
import { checkRequiredFormInputs } from "../../helpers";

import logo from "../../assets/images/logo.png";
import frame from "../../assets/images/authentication-frame.png";
import LoadingCircle from "../../components/loading-circle";
import TextInput from "../../components/inputs/TextInput";
import Button from "../../components/button";

import "./styles.css";

const formInputsInitialState = {
  email: {
    type: "email",
    name: "email",
    isRequired: true,
    value: "",
  },
  password: {
    type: "password",
    name: "password",
    isRequired: true,
    value: "",
  },
};

const Login = () => {
  const { t: translation } = useTranslation("login");
  const { dispatchToast } = useContext(ToastContext);
  const { login } = useAuth();
  const [formInputs, setFormInputs] = useState(formInputsInitialState);
  const [isLoading, setIsLoading] = useState(false);
  const [areFormInputsValid, setAreFormInputsValid] = useState(true);

  const onSubmitHandler = async (event) => {
    event.preventDefault();

    if (!checkRequiredFormInputs(formInputs)) {
      setAreFormInputsValid(false);
      return;
    }

    setAreFormInputsValid(true);

    try {
      setIsLoading(true);

      const isLoggedIn = await login(
        formInputs.email.value,
        formInputs.password.value
      );

      const tokenResult = await isLoggedIn.user.getIdTokenResult();

      if (tokenResult.claims.device) {
        setIsLoading(false);
        dispatchToast({
          payload: {
            message: {
              EN: "The password is invalid or the user does not have a password.",
              AR: "كلمة المرور غير صالحة أو ليس لدى المستخدم كلمة مرور.",
            },
            status: "fail",
          },
        });

        return;
      }
    } catch (error) {
      dispatchToast({
        payload: {
          message: {
            EN: error.message,
            AR: error.message,
          },
          status: "fail",
        },
      });

      setIsLoading(false);
    }
  };

  const onFormInputsChangeHandler = (name, value) => {
    setFormInputs((previousState) => ({
      ...previousState,
      [name]: {
        ...previousState[name],
        value: value,
      },
    }));
  };

  return (
    <div className="authentication-page">
      <div className="authentication-page-form-row">
        <div className="authentication-page-form-column">
          <img src={logo} alt="logo" className="authentication-logo" />

          <h1 className="authentication-page-header">
            {translation("header")}
          </h1>

          <h3 className="authentication-title">
            {translation("welcome_back")}
          </h3>

          <p className="authentication-subtitle">
            {translation("account_login")}
          </p>

          <form onSubmit={onSubmitHandler}>
            <fieldset>
              <TextInput
                formInput={formInputs.email}
                onFormInputsChangeHandler={onFormInputsChangeHandler}
                placeholder={translation("email_placeholder")}
              />

              <TextInput
                formInput={formInputs.password}
                onFormInputsChangeHandler={onFormInputsChangeHandler}
                placeholder={translation("password_placeholder")}
              />

              {!areFormInputsValid && (
                <p className="required-inputs">
                  {translation("form_inputs_validation_notice")}
                </p>
              )}
            </fieldset>

            {isLoading ? (
              <LoadingCircle />
            ) : (
              <Button
                type="submit"
                variant="primary"
                color="black"
                isDisabled={isLoading}
                onButtonClickHandler={onSubmitHandler}
                style={{ margin: "12px 0" }}
              >
                {translation("login_action_button")}
              </Button>
            )}
          </form>

          <Link to="/password/reset" className="authentication-link">
            {translation("forgot_password_link")}
          </Link>

          <br />

          <a
            className="authentication-link"
            href="https://atheii.com/get-in-touch/"
            target="_blank"
            rel="noreferrer"
          >
            {translation("contactUs")}
          </a>
        </div>

        <div className="authentication-page-form-column hide-tablet-and-down">
          <img src={frame} alt="frame" className="authentication-frame" />
        </div>
      </div>
    </div>
  );
};

export default Login;
