import { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import { useAuth } from "../contexts/AuthContext";

import LoadingCircle from "../components/loading-circle";
import Toast from "../components/toast";
import Navigation from "../components/navigation";
import ProtectedRoute from "./ProtectedRoute";

// --------------------------------------------------------
// Dashboard
const Dashboard = lazy(() => import("../pages/dashboard/Dashboard"));

// --------------------------------------------------------
// Authentication
const Login = lazy(() => import("../pages/authentication/Login"));
const ResetPassword = lazy(() =>
  import("../pages/authentication/ResetPassword")
);

// --------------------------------------------------------
// Client management
const ViewClients = lazy(() => import("../pages/clients/ViewClients"));
const AddClient = lazy(() => import("../pages/clients/AddClient"));
const EditClient = lazy(() => import("../pages/clients/EditClient"));
const ViewClient = lazy(() => import("../pages/clients/ViewClient"));

// --------------------------------------------------------
// Advertisement management
const ViewAdvertisements = lazy(() =>
  import("../pages/advertisements/ViewAdvertisements")
);

// --------------------------------------------------------
// Licenses management
const ViewLicenses = lazy(() => import("../pages/licenses/ViewLicenses"));
const AddLicense = lazy(() => import("../pages/licenses/AddLicense"));
const EditLicense = lazy(() => import("../pages/licenses/EditLicense"));
const ViewLicense = lazy(() => import("../pages/licenses/ViewLicense"));

// --------------------------------------------------------
// Story language management
const ViewStoryLanguages = lazy(() =>
  import("../pages/story-language/ViewStoryLanguages")
);
const AddStoryLanguage = lazy(() =>
  import("../pages/story-language/AddStoryLanguage")
);
const EditStoryLanguage = lazy(() =>
  import("../pages/story-language/EditStoryLanguage")
);

// --------------------------------------------------------
// Bucket story topics management
const ViewBucketStoryTopics = lazy(() =>
  import("../pages/bucket/story-topic/ViewBucketStoryTopics")
);
const AddBucketStoryTopic = lazy(() =>
  import("../pages/bucket/story-topic/AddBucketStoryTopic")
);
const EditBucketStoryTopic = lazy(() =>
  import("../pages/bucket/story-topic/EditBucketStoryTopic")
);
const ViewBucketStoryTopic = lazy(() =>
  import("../pages/bucket/story-topic/ViewBucketStoryTopic")
);

// --------------------------------------------------------
// Bucket story durations management
const ViewBucketStoryDurations = lazy(() =>
  import("../pages/bucket/story-duration/ViewBucketStoryDurations")
);
const AddBucketStoryDuration = lazy(() =>
  import("../pages/bucket/story-duration/AddBucketStoryDuration")
);
const EditBucketStoryDuration = lazy(() =>
  import("../pages/bucket/story-duration/EditBucketStoryDuration")
);
const ViewBucketStoryDuration = lazy(() =>
  import("../pages/bucket/story-duration/ViewBucketStoryDuration")
);

// --------------------------------------------------------
// Bucket stories management
const ViewBucketStories = lazy(() =>
  import("../pages/bucket/story/ViewBucketStories")
);
const AddBucketStory = lazy(() =>
  import("../pages/bucket/story/AddBucketStory")
);
const EditBucketStory = lazy(() =>
  import("../pages/bucket/story/EditBucketStory")
);
const ViewBucketStory = lazy(() =>
  import("../pages/bucket/story/ViewBucketStory")
);

// --------------------------------------------------------
// App language management
const ViewAppLanguages = lazy(() =>
  import("../pages/app-language/ViewAppLanguages")
);
const AddAppLanguage = lazy(() =>
  import("../pages/app-language/AddAppLanguage")
);
const EditAppLanguage = lazy(() =>
  import("../pages/app-language/EditAppLanguage")
);
const ViewAppLanguage = lazy(() =>
  import("../pages/app-language/ViewAppLanguage")
);

// --------------------------------------------------------
// Device Management
const ViewDevices = lazy(() => import("../pages/devices/ViewDevices"));
const AddDevice = lazy(() => import("../pages/devices/AddDevice"));
const EditDevice = lazy(() => import("../pages/devices/EditDevice"));
const ViewDevice = lazy(() => import("../pages/devices/ViewDevice"));

// --------------------------------------------------------
// Story topic management
const ViewStoryTopics = lazy(() =>
  import("../pages/story-topic/ViewStoryTopics")
);
const AddStoryTopic = lazy(() => import("../pages/story-topic/AddStoryTopic"));
const EditStoryTopic = lazy(() =>
  import("../pages/story-topic/EditStoryTopic")
);
const ViewStoryTopic = lazy(() =>
  import("../pages/story-topic/ViewStoryTopic")
);

// --------------------------------------------------------
// Story duration management
const ViewStoryDurations = lazy(() =>
  import("../pages/story-duration/ViewStoryDurations")
);
const AddStoryDuration = lazy(() =>
  import("../pages/story-duration/AddStoryDuration")
);
const EditStoryDuration = lazy(() =>
  import("../pages/story-duration/EditStoryDuration")
);
const ViewStoryDuration = lazy(() =>
  import("../pages/story-duration/ViewStoryDuration")
);

// --------------------------------------------------------
// Story management
const ViewStories = lazy(() => import("../pages/story/ViewStories"));
const AddStory = lazy(() => import("../pages/story/AddStory"));
const EditStory = lazy(() => import("../pages/story/EditStory"));
const ViewStory = lazy(() => import("../pages/story/ViewStory"));
const ViewPublicStory = lazy(() => import("../pages/story/ViewPublicStory"));

// --------------------------------------------------------
// Error pages
const NotFound = lazy(() => import("../pages/error/NotFound"));
const Unauthorized = lazy(() => import("../pages/error/Unauthorized"));

const Routes = () => {
  const { currentUser } = useAuth();

  return (
    <Router>
      <Toast />

      {currentUser && <Navigation />}

      <Suspense fallback={<LoadingCircle isFullPageLoading={true} />}>
        <Switch>
          {/* Dashboard routes */}
          {/* -------------------------------------------------- */}
          <ProtectedRoute
            exact
            path={["/", "/dashboard"]}
            component={Dashboard}
            isRestricted={false}
          />

          {/* Authentication routes */}
          {/* -------------------------------------------------- */}
          <Route path="/login">
            {currentUser ? <Redirect to="/" /> : <Login />}
          </Route>

          <Route path="/password/reset">
            {currentUser ? <Redirect to="/" /> : <ResetPassword />}
          </Route>

          {/* Clients management routes */}
          {/* -------------------------------------------------- */}
          <ProtectedRoute
            exact
            path="/clients"
            component={ViewClients}
            isRestricted={true}
            allowedUserTypes={["superuser"]}
          />

          <ProtectedRoute
            exact
            path="/clients/add"
            component={AddClient}
            isRestricted={true}
            allowedUserTypes={["superuser"]}
          />

          <ProtectedRoute
            exact
            path="/clients/:id/edit"
            component={EditClient}
            isRestricted={true}
            allowedUserTypes={["superuser"]}
          />

          <ProtectedRoute
            exact
            path="/clients/:id"
            component={ViewClient}
            isRestricted={true}
            allowedUserTypes={["superuser"]}
          />

          {/* Advertisements management routes */}
          {/* -------------------------------------------------- */}
          <ProtectedRoute
            exact
            path="/advertisements"
            component={ViewAdvertisements}
            isRestricted={true}
            allowedUserTypes={["superuser", "client", "advertisementClient"]}
          />

          {/* Licenses management routes */}
          {/* -------------------------------------------------- */}
          <ProtectedRoute
            exact
            path="/licenses"
            component={ViewLicenses}
            isRestricted={true}
            allowedUserTypes={["superuser"]}
          />

          <ProtectedRoute
            exact
            path="/licenses/add"
            component={AddLicense}
            isRestricted={true}
            allowedUserTypes={["superuser"]}
          />

          <ProtectedRoute
            exact
            path="/licenses/:id/edit"
            component={EditLicense}
            isRestricted={true}
            allowedUserTypes={["superuser"]}
          />

          <ProtectedRoute
            exact
            path="/licenses/:id"
            component={ViewLicense}
            isRestricted={true}
            allowedUserTypes={["superuser"]}
          />

          {/* Story languages routes */}
          {/* -------------------------------------------------- */}
          <ProtectedRoute
            exact
            path="/story-languages"
            component={ViewStoryLanguages}
            isRestricted={true}
            allowedUserTypes={["superuser", "client"]}
          />

          <ProtectedRoute
            exact
            path="/story-languages/add"
            component={AddStoryLanguage}
            isRestricted={true}
            allowedUserTypes={["superuser", "client"]}
          />

          <ProtectedRoute
            exact
            path="/story-languages/:id/edit"
            component={EditStoryLanguage}
            isRestricted={true}
            allowedUserTypes={["superuser", "client"]}
          />

          {/* Bucket story topics routes */}
          {/* -------------------------------------------------- */}
          <ProtectedRoute
            exact
            path="/bucket/story-topics"
            component={ViewBucketStoryTopics}
            isRestricted={true}
            allowedUserTypes={["superuser"]}
          />

          <ProtectedRoute
            exact
            path="/bucket/story-topics/add"
            component={AddBucketStoryTopic}
            isRestricted={true}
            allowedUserTypes={["superuser"]}
          />

          <ProtectedRoute
            exact
            path="/bucket/story-topics/:id/edit"
            component={EditBucketStoryTopic}
            isRestricted={true}
            allowedUserTypes={["superuser"]}
          />

          <ProtectedRoute
            exact
            path="/bucket/story-topics/:id"
            component={ViewBucketStoryTopic}
            isRestricted={true}
            allowedUserTypes={["superuser"]}
          />

          {/* Bucket story durations routes */}
          {/* -------------------------------------------------- */}
          <ProtectedRoute
            exact
            path="/bucket/story-durations"
            component={ViewBucketStoryDurations}
            isRestricted={true}
            allowedUserTypes={["superuser"]}
          />

          <ProtectedRoute
            exact
            path="/bucket/story-durations/add"
            component={AddBucketStoryDuration}
            isRestricted={true}
            allowedUserTypes={["superuser"]}
          />

          <ProtectedRoute
            exact
            path="/bucket/story-durations/:id/edit"
            component={EditBucketStoryDuration}
            isRestricted={true}
            allowedUserTypes={["superuser"]}
          />

          <ProtectedRoute
            exact
            path="/bucket/story-durations/:id"
            component={ViewBucketStoryDuration}
            isRestricted={true}
            allowedUserTypes={["superuser"]}
          />

          {/* Bucket stories routes */}
          {/* -------------------------------------------------- */}
          <ProtectedRoute
            exact
            path="/bucket/stories"
            component={ViewBucketStories}
            isRestricted={true}
            allowedUserTypes={["superuser"]}
          />

          <ProtectedRoute
            exact
            path="/bucket/stories/add"
            component={AddBucketStory}
            isRestricted={true}
            allowedUserTypes={["superuser"]}
          />

          <ProtectedRoute
            exact
            path="/bucket/stories/:id"
            component={ViewBucketStory}
            isRestricted={true}
            allowedUserTypes={["superuser"]}
          />

          <ProtectedRoute
            exact
            path="/bucket/stories/:id/edit"
            component={EditBucketStory}
            isRestricted={true}
            allowedUserTypes={["superuser"]}
          />

          {/* App languages routes */}
          {/* -------------------------------------------------- */}
          <ProtectedRoute
            exact
            path="/app-languages"
            component={ViewAppLanguages}
            isRestricted={true}
            allowedUserTypes={["superuser"]}
          />

          <ProtectedRoute
            exact
            path="/app-languages/add"
            component={AddAppLanguage}
            isRestricted={true}
            allowedUserTypes={["superuser"]}
          />

          <ProtectedRoute
            exact
            path="/app-languages/:id"
            component={ViewAppLanguage}
            isRestricted={true}
            allowedUserTypes={["superuser"]}
          />

          <ProtectedRoute
            exact
            path="/app-languages/:id/edit"
            component={EditAppLanguage}
            isRestricted={true}
            allowedUserTypes={["superuser"]}
          />

          {/* Devices management routes */}
          {/* -------------------------------------------------- */}
          <ProtectedRoute
            exact
            path="/devices"
            component={ViewDevices}
            isRestricted={true}
            allowedUserTypes={["superuser", "client"]}
          />

          <ProtectedRoute
            exact
            path="/devices/add"
            component={AddDevice}
            isRestricted={true}
            allowedUserTypes={["superuser", "client"]}
          />

          <ProtectedRoute
            exact
            path="/devices/:id"
            component={ViewDevice}
            isRestricted={true}
            allowedUserTypes={["superuser", "client"]}
          />

          <ProtectedRoute
            exact
            path="/devices/:id/edit"
            component={EditDevice}
            isRestricted={true}
            allowedUserTypes={["superuser", "client"]}
          />

          {/* Story topics routes */}
          {/* -------------------------------------------------- */}
          <ProtectedRoute
            exact
            path="/story-topics"
            component={ViewStoryTopics}
            isRestricted={true}
            allowedUserTypes={["client"]}
          />

          <ProtectedRoute
            exact
            path="/story-topics/add"
            component={AddStoryTopic}
            isRestricted={true}
            allowedUserTypes={["client"]}
          />

          <ProtectedRoute
            exact
            path="/story-topics/:id/edit"
            component={EditStoryTopic}
            isRestricted={true}
            allowedUserTypes={["client"]}
          />

          <ProtectedRoute
            exact
            path="/story-topics/:id"
            component={ViewStoryTopic}
            isRestricted={true}
            allowedUserTypes={["client"]}
          />

          {/* Story durations routes */}
          {/* -------------------------------------------------- */}
          <ProtectedRoute
            exact
            path="/story-durations"
            component={ViewStoryDurations}
            isRestricted={true}
            allowedUserTypes={["client"]}
          />

          <ProtectedRoute
            exact
            path="/story-durations/add"
            component={AddStoryDuration}
            isRestricted={true}
            allowedUserTypes={["client"]}
          />

          <ProtectedRoute
            exact
            path="/story-durations/:id/edit"
            component={EditStoryDuration}
            isRestricted={true}
            allowedUserTypes={["client"]}
          />

          <ProtectedRoute
            exact
            path="/story-durations/:id"
            component={ViewStoryDuration}
            isRestricted={true}
            allowedUserTypes={["client"]}
          />

          {/* Stories routes */}
          {/* -------------------------------------------------- */}
          <ProtectedRoute
            exact
            path="/stories"
            component={ViewStories}
            isRestricted={true}
            allowedUserTypes={["client"]}
          />

          <ProtectedRoute
            exact
            path="/stories/add"
            component={AddStory}
            isRestricted={true}
            allowedUserTypes={["client"]}
          />

          <ProtectedRoute
            exact
            path="/stories/:id/edit"
            component={EditStory}
            isRestricted={true}
            allowedUserTypes={["client"]}
          />

          <ProtectedRoute
            exact
            path="/stories/:id"
            component={ViewStory}
            isRestricted={true}
            allowedUserTypes={["client"]}
          />

          <Route exact path="/stories/public/:id">
            <ViewPublicStory />
          </Route>

          {/* Error handling routes */}
          {/* -------------------------------------------------- */}
          <Route path="/unauthorized">
            <Unauthorized />
          </Route>

          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </Suspense>
    </Router>
  );
};

export default Routes;
