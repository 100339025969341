import { useState } from "react";
import { NavLink } from "react-router-dom";
import {
  AppBar,
  CssBaseline,
  Drawer,
  Hidden,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@material-ui/core";
import {
  Menu as MenuIcon,
  Dashboard as DashboardIcon,
  Devices as DevicesIcon,
  SupervisorAccount as SupervisorAccountIcon,
  ArtTrack as ArtTrackIcon,
  MenuBook as MenuBookIcon,
  Language as LanguageIcon,
  Receipt as ReceiptIcon,
  PresentToAll as PresentToAllIcon,
  Title as TitleIcon,
  AccessTime as AccessTimeIcon,
} from "@material-ui/icons";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

import logo from "../../assets/images/logo.png";
import { useAuth } from "../../contexts/AuthContext";

import styles from "./styles";

import Logout from "../../pages/authentication/Logout";
import LanguageSwitcher from "../language-switcher";
import NestedList from "./NestedList";

const Navigation = (props) => {
  const { t: translation } = useTranslation("components", {
    keyPrefix: "navigation",
  });
  const { currentUser, userType } = useAuth();
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <>
      <div className={classes.toolbar}>
        <img
          src={logo}
          alt="logo"
          width="auto"
          height="100px"
          className={classes.navigationLogo}
        />
      </div>

      {/* Dashboard */}
      {/* -------------------------------------------------- */}
      <NavigationListItem
        userType={userType}
        roles={["superuser", "client", "advertisementClient"]}
        link="/"
        exact
        text={translation("dashboardPage")}
        icon={<DashboardIcon />}
        classes={classes}
      />

      {/* Clients */}
      {/* -------------------------------------------------- */}
      <NavigationListItem
        userType={userType}
        roles={["superuser"]}
        link="/clients"
        text={translation("clientsPage")}
        icon={<SupervisorAccountIcon />}
        classes={classes}
      />

      {/* Licenses */}
      {/* -------------------------------------------------- */}
      <NavigationListItem
        userType={userType}
        roles={["superuser"]}
        link="/licenses"
        text={translation("licensesPage")}
        icon={<ReceiptIcon />}
        classes={classes}
      />

      {/* Devices */}
      {/* -------------------------------------------------- */}
      <NavigationListItem
        userType={userType}
        roles={["superuser", "client"]}
        link="/devices"
        text={translation("devicesPage")}
        icon={<DevicesIcon />}
        classes={classes}
      />

      {/* Story languages */}
      {/* -------------------------------------------------- */}
      {userType !== "superuser" && (
        <NavigationListItem
          userType={userType}
          roles={["superuser", "client"]}
          link="/story-languages"
          text={translation("storyLanguagePage")}
          icon={<LanguageIcon />}
          classes={classes}
        />
      )}

      {/* Bucket (story topics, story durations, stories) */}
      {/* -------------------------------------------------- */}
      <NestedList
        userType={userType}
        roles={["superuser"]}
        links={[
          {
            link: "/bucket/story-topics",
            text: translation("bucketStoryTopicsPage"),
          },
          {
            link: "/bucket/story-durations",
            text: translation("bucketStoryDurationsPage"),
          },
          {
            link: "/bucket/stories",
            text: translation("bucketStoriesPage"),
          },
          {
            link: "/story-languages",
            text: translation("storyLanguagePage"),
          },
        ]}
        icon={<PresentToAllIcon />}
        listText={translation("bucketText")}
      />

      {/* Story Topics */}
      {/* -------------------------------------------------- */}
      <NavigationListItem
        userType={userType}
        roles={["client"]}
        link="/story-topics"
        text={translation("storyTopicsPage")}
        icon={<TitleIcon />}
        classes={classes}
      />

      {/* Story Durations */}
      {/* -------------------------------------------------- */}
      <NavigationListItem
        userType={userType}
        roles={["client"]}
        link="/story-durations"
        text={translation("storyDurationsPage")}
        icon={<AccessTimeIcon />}
        classes={classes}
      />

      {/* Stories */}
      {/* -------------------------------------------------- */}
      <NavigationListItem
        userType={userType}
        roles={["client"]}
        link="/stories"
        text={translation("storiesPage")}
        icon={<MenuBookIcon />}
        classes={classes}
      />

      {/* App languages */}
      {/* -------------------------------------------------- */}
      <NavigationListItem
        userType={userType}
        roles={["superuser"]}
        link="/app-languages"
        text={translation("appLanguagePage")}
        icon={<LanguageIcon />}
        classes={classes}
      />

      {/* Advertisements */}
      {/* -------------------------------------------------- */}
      <NavigationListItem
        userType={userType}
        roles={["superuser", "client", "advertisementClient"]}
        link="/advertisements"
        text={translation("advertisementsPage")}
        icon={<ArtTrackIcon />}
        classes={classes}
      />

      {/*<a
        className={classes.contactUsLink}
        href="https://atheii.com/get-in-touch/"
        target="_blank"
        rel="noreferrer"
      >
        {translation("contactUs")}
      </a>*/}
    </>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />

      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar
          className={classes.toolbar}
          style={{ alignItems: "stretch", padding: "0 12px" }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>

          <Typography variant="h2" noWrap className={classes.headerText}>
            <span style={{ display: "block" }}>
              {translation("welcomeBack")}
            </span>

            <span style={{ display: "block", fontSize: "16px" }}>
              {currentUser.email}
            </span>
          </Typography>

          <LanguageSwitcher />

          <Logout />
        </Toolbar>
      </AppBar>

      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>

        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            anchor="left"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </div>
  );
};

const NavigationListItem = ({
  userType,
  roles,
  link,
  text,
  icon,
  classes,
  ...rest
}) => {
  return roles.includes(userType) ? (
    <NavLink
      to={link}
      className={classes?.link}
      activeClassName={classes?.activeLink}
      {...rest}
    >
      <ListItem className={classes?.listItem}>
        <ListItemIcon className={classes?.listItemIcon}>{icon}</ListItemIcon>

        <ListItemText className={classes?.listItemText}>{text}</ListItemText>
      </ListItem>
    </NavLink>
  ) : null;
};

const useStyles = makeStyles((theme) => styles(theme));

export default Navigation;
