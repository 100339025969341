import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { ExitToApp as ExitToAppIcon } from "@material-ui/icons";

import { useAuth } from "../../contexts/AuthContext";
import ToastContext from "../../contexts/ToastContext";

const Logout = () => {
  const history = useHistory();
  const { dispatchToast } = useContext(ToastContext);
  const { logout } = useAuth();

  const onLogoutHandler = async (event) => {
    if (event.type === "click" || event.key === "Enter" || event.key === " ") {
      try {
        await logout();

        history.push("/");
      } catch (error) {
        dispatchToast({
          payload: {
            message: {
              EN: error.message,
              AR: error.message,
            },
            status: "fail",
          },
        });
      }
    }
  };

  return (
    <ExitToAppIcon
      onClick={onLogoutHandler}
      onKeyDown={onLogoutHandler}
      tabIndex="0"
      role="button"
      aria-pressed="false"
      className="logout-icon"
    />
  );
};

export default Logout;
