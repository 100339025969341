import { CircularProgress } from "@material-ui/core";

import "./styles.css";

const LoadingCircle = ({ isFullPageLoading = false, ...rest }) => {
  return (
    <div className={`${isFullPageLoading ? "full-page-load" : ""}`}>
      <CircularProgress size={30} {...rest} />
    </div>
  );
};

export default LoadingCircle;
