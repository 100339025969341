import { useEffect, useContext } from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

import { useAuth } from "../../contexts/AuthContext";
import ToastContext from "../../contexts/ToastContext";

import frame from "../../assets/images/not-found.png";
import logo from "../../assets/images/logo.png";
import Button from "../../components/button";

import "./styles.css";

const Unauthorized = () => {
  const { t: translation } = useTranslation("unauthorized");
  const history = useHistory();
  const { dispatchToast } = useContext(ToastContext);
  const { currentUser, logout } = useAuth();

  const goBackHandler = () => {
    history.goBack();
  };

  useEffect(() => {
    if (!currentUser) return;

    const handleLogout = async () => {
      try {
        await logout();

        history.push("/");
      } catch (error) {
        dispatchToast({
          payload: {
            message: {
              EN: error.message,
              AR: error.message,
            },
            status: "fail",
          },
        });
      }
    };

    handleLogout();
  }, [currentUser, history, logout, dispatchToast]);

  return (
    <div
      className={`${
        currentUser ? "under-nav" : "full-height-page"
      } error-page-layout`}
    >
      {!currentUser && (
        <div className="logo-container">
          <img src={logo} alt="logo" />
        </div>
      )}

      <div className="error-page-content-container">
        <h1>{translation("title")}</h1>

        <h3>{translation("subtitle")}</h3>

        <img src={frame} alt="not found" />

        <Button
          variant="primary"
          color="black"
          onButtonClickHandler={goBackHandler}
          style={{ margin: "12px 0", width: "auto" }}
        >
          {translation("actionButton")}
        </Button>
      </div>
    </div>
  );
};

export default Unauthorized;
