const drawerWidth = 240;

const styles = (theme) => {
  return {
    root: {
      display: "flex",
      direction: "ltr ",
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
      backgroundColor: "var(--white)",
      boxShadow: "none",
      borderBottom: "2px solid #cbcbcb",
      padding: "12px 0",
    },
    menuButton: {
      [theme.breakpoints.up("sm")]: {
        display: "none !important",
      },
      color: "var(--black)",
      marginRight: "12px",
      alignSelf: "center",
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: "#f9f9f9 !important",
      borderRight: "none",
    },
    headerText: {
      color: "#202020",
      fontSize: "25px !important",
      fontFamily: "var(--base-font-family)",
      fontWeight: "var(--regular-font-weight) !important",
      alignSelf: "center",
    },
    listItem: {
      padding: "0 !important",
      direction: "ltr",
    },
    listItemText: {
      margin: "0px !important",
      textAlign: "start",
    },
    listItemIcon: {
      minWidth: "39px",
      color: "inherit",
    },
    link: {
      margin: 0,
      width: "100%",
      textAlign: "start",
      fontSize: "16px",
      color: "#707070",
      cursor: "pointer",
      padding: "12px 16px",
      outline: "none",
      "&:hover, &:focus": {
        backgroundColor: "#cbcbcb",
        color: "var(--white)",
      },
    },
    activeLink: {
      backgroundColor: "#202020 !important",
      color: "var(--white)",
    },
    navigationLogo: {
      objectFit: "contain",
      margin: "16px",
      marginInlineStart: "14px",
    },
    contactUsLink: {
      marginTop: "auto",
      padding: "12px 16px",
      fontSize: "16px",
    },
  };
};

export default styles;
