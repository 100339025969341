import { useState, useEffect, createContext, useContext } from "react";
import { auth } from "../firebase/authentication";

import LoadingCircle from "../components/loading-circle";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);
  const [userType, setUserType] = useState("");
  const [token, setToken] = useState("");

  const signUp = (email, password) => {
    return auth.createUserWithEmailAndPassword(email, password);
  };

  const login = (email, password) => {
    return auth.signInWithEmailAndPassword(email, password);
  };

  const logout = () => {
    return auth.signOut();
  };

  const resetPassword = (email) => {
    return auth.sendPasswordResetEmail(email);
  };

  const updateEmail = (email) => {
    return currentUser.updateEmail(email);
  };

  const updatePassword = (password) => {
    return currentUser.updatePassword(password);
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const tokenResult = await user.getIdTokenResult();

        setToken(tokenResult.token);

        if (tokenResult.claims.device) {
          setLoading(false);

          return;
        }

        if (tokenResult.claims.superuser) setUserType("superuser");

        if (tokenResult.claims.client) {
          setUserType("client");
        }

        if (tokenResult.claims.advertisementClient) {
          setUserType("advertisementClient");
        }
      }

      setCurrentUser(user);

      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    userType,
    token,
    login,
    signUp,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
  };

  return (
    <AuthContext.Provider value={value}>
      {loading ? <LoadingCircle isFullPageLoading={true} /> : children}
    </AuthContext.Provider>
  );
};
