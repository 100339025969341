import { useTranslation } from "react-i18next";

import { useLanguage } from "../../contexts/LanguageContext";

import "./styles.css";

const LanguageSwitcher = () => {
  const { t: translation } = useTranslation("components", {
    keyPrefix: "languageSwitcher",
  });
  const { language, changeLanguage } = useLanguage();

  return (
    <button className="language-switcher-button" onClick={changeLanguage}>
      <span className={`${language === "en" ? "active-language" : ""}`}>
        {translation("english")}
      </span>

      <span className="vertical-split"></span>

      <span className={`${language === "ar" && "active-language"}`}>
        {translation("arabic")}
      </span>
    </button>
  );
};

export default LanguageSwitcher;
