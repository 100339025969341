import { useState } from "react";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@material-ui/core";
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from "@material-ui/icons";

const NestedList = ({ userType, roles, links, icon, listText }) => {
  const classes = useStyles();
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleClick = () => {
    setIsCollapsed(!isCollapsed);
  };

  return roles.includes(userType) ? (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}
    >
      <ListItem className={classes.listItemButton} button onClick={handleClick}>
        <ListItemIcon>{icon}</ListItemIcon>

        <ListItemText primary={listText} className={classes.list} />

        {isCollapsed ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>

      <Collapse in={isCollapsed} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {links.map((element, index) => (
            <NavLink
              key={index}
              to={element.link}
              className={classes.link}
              activeClassName={classes.activeLink}
            >
              <ListItem className={classes.nested}>
                <ListItemText primary={element.text} />
              </ListItem>
            </NavLink>
          ))}
        </List>
      </Collapse>
    </List>
  ) : null;
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  listItemButton: {
    paddingInline: "16px !important",
    paddingBlock: "8px !important",
  },
  nested: {
    paddingInlineStart: "55px !important",
  },
  list: {
    color: "#707070",
  },
  link: {
    display: "block",
    color: "#707070",
    "&:hover, &:focus": {
      backgroundColor: "#cbcbcb",
      color: "white",
    },
  },
  activeLink: {
    backgroundColor: "#202020 !important",
    color: "white",
  },
}));

export default NestedList;
