import "./styles.css";

const Button = ({
  onButtonClickHandler,
  isDisabled,
  variant,
  color,
  children,
  ...rest
}) => {
  return (
    <button
      {...rest}
      className={`custom-button ${variant}-button is-${color}`}
      onClick={onButtonClickHandler}
      disabled={isDisabled}
    >
      {children}
    </button>
  );
};

export default Button;
