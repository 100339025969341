import { useState } from "react";
import { useTranslation } from "react-i18next";

import "./styles.css";

const emailRegex =
  /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const TextInput = ({
  formInput,
  onFormInputsChangeHandler,
  placeholder,
  ...rest
}) => {
  const { t: translation } = useTranslation("common", {
    keyPrefix: "errorMessages",
  });
  const [inputErrorMessage, setInputErrorMessage] = useState("");

  const onInputChangeHandler = (event) => {
    const { name, value } = event.target;

    if (formInput.isRequired && value === "") {
      setInputErrorMessage("required");
    } else if (
      formInput.type === "email" &&
      !emailRegex.test(value.toLowerCase())
    ) {
      setInputErrorMessage("invalidEmail");
    } else {
      setInputErrorMessage("");
    }

    onFormInputsChangeHandler(name, value);
  };

  let Component = "input";

  if (formInput?.type === "textarea") {
    Component = "textarea";
  }

  return (
    <div className="input-container">
      <label className="input-label">
        <span>
          {placeholder}
          {formInput.isRequired && "*"}
        </span>

        <Component
          {...rest}
          type={formInput.type}
          name={formInput.name}
          required={formInput.isRequired}
          value={formInput.value}
          onChange={onInputChangeHandler}
        />
      </label>

      {inputErrorMessage && (
        <p className="input-error-message">{translation(inputErrorMessage)}</p>
      )}
    </div>
  );
};

export default TextInput;
