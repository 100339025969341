import { AuthProvider } from "./contexts/AuthContext";
import { LanguageProvider } from "./contexts/LanguageContext";
import ToastContext from "./contexts/ToastContext";
import useToast from "./hooks/useToast";

import Routes from "./routes";

const App = () => {
  const { toastState, dispatchToast } = useToast();

  return (
    <ToastContext.Provider value={{ toastState, dispatchToast }}>
      <LanguageProvider>
        <AuthProvider>
          <Routes />
        </AuthProvider>
      </LanguageProvider>
    </ToastContext.Provider>
  );
};

export default App;
