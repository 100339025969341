import { useState, useEffect, useContext } from "react";
import { Snackbar, IconButton, Slide } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Close as CloseIcon } from "@material-ui/icons";

import { useLanguage } from "../../contexts/LanguageContext";
import ToastContext from "../../contexts/ToastContext";

import "./styles.css";

const Toast = () => {
  const classes = useStyles();
  const { language } = useLanguage();
  const [toastPack, setToastPack] = useState([]);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState(undefined);
  const { toastState } = useContext(ToastContext);

  useEffect(() => {
    let isMounted = true;

    if (toastPack.length && !toastMessage) {
      if (isMounted) {
        setToastMessage({ ...toastPack[0] });
        setToastPack((prev) => prev.slice(1));
        setIsToastOpen(true);
      }
    } else if (toastPack.length && toastMessage && isToastOpen) {
      isMounted && setIsToastOpen(false);
    }

    return () => {
      isMounted = false;
    };
  }, [toastPack, toastMessage, isToastOpen]);

  const openToast = (message) => {
    setToastPack((previousState) => [
      ...previousState,
      { ...message, key: new Date().getTime() },
    ]);
  };

  const closeToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsToastOpen(false);
  };

  const handleExitedToast = () => {
    setToastMessage(undefined);
  };

  useEffect(() => {
    let isMounted = true;

    toastState?.message && isMounted && openToast(toastState);

    return () => {
      isMounted = false;
    };
  }, [toastState]);

  return (
    <Snackbar
      key={toastMessage?.key}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      ContentProps={{
        className: classes[toastMessage?.status],
      }}
      open={isToastOpen}
      onClose={closeToast}
      TransitionProps={{ onExited: handleExitedToast }}
      message={toastMessage?.message[language === "en" ? "EN" : "AR"]}
      autoHideDuration={6000}
      TransitionComponent={Slide}
      action={
        <>
          <IconButton
            aria-label="close"
            size="small"
            color="inherit"
            onClick={closeToast}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </>
      }
    />
  );
};

const useStyles = makeStyles(() => ({
  success: {
    backgroundColor: "var(--success)",
  },
  warning: {
    backgroundColor: "var(--warning)",
  },
  fail: {
    backgroundColor: "var(--fail)",
  },
}));

export default Toast;
